import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {  } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from './router'
import * as VueGoogleMaps from "vue2-google-maps"
/* import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics"; */

library.add(faFacebook, faInstagram)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

/* const firebaseConfig = {
  apiKey: "AIzaSyAjsZVhu88X5zQ7iBjkmylosO2hvP-n5Q4",
  authDomain: "jane-schmidt.firebaseapp.com",
  projectId: "jane-schmidt",
  storageBucket: "jane-schmidt.appspot.com",
  messagingSenderId: "39853399258",
  appId: "1:39853399258:web:095db24ee4743ce46419e3",
  measurementId: "G-QWYWHLZJ2K"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app); */

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBkKUF5d2VYr_Jzr_PB5PhCRzFuJyt7FTs",
    libraries: "places"
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
