import { render, staticRenderFns } from "./theFooter.vue?vue&type=template&id=0af3f5de&"
var script = {}
import style0 from "./theFooter.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports