<template>
  <div class="main-container">
    <div class="info-container">
      <div class="text-container">
        <h1>Kontakt mig</h1>
        <p>Kontakt mig gerne på en af følgende måder:</p>
        <p class="bold">Jane Schmidt</p>
        <p>Tlf.: <span class="bold">+45 22 61 69 22</span></p>
        <p>Email.: <a href = "mailto: jane@supersurf.dk">jane@supersurf.dk</a></p>
      </div>
      <AddGoogleMap/>
    </div>
    <div class="image-container">
      <img src="../assets/nature2.jpg" alt="">
    </div>
  </div>
</template>

<script>
import AddGoogleMap from '../components/AddGoogleMap.vue';
import {importantMeta} from '../services/seoService.js';

export default {
  name: 'App',
  components: {
    AddGoogleMap
  },

  mounted() {
    importantMeta(document, 'Kontakt', 'På denne side kan du kontakte Jane Schmidt')
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vendors/bootstrap-vue/index";

.main-container {
  margin: 100px 100px;
  display: flex;
  justify-content: space-around;


  .text-container {
    margin: 0 200px 100px 0;
    text-align: center;

    h1 {
      font-size: 40px;
      color: $primary;
      margin: 0 0 30px 0;
    }

    .bold {
      font-weight: 600;
    }

    .info-container{
      
    }
  }

  .image-container {
    img {
      max-width: 500px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 2%;
    }
  }
}
</style>