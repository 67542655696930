<template>
  <div id="app">
    <navbar/>
    <router-view/>
  <the-footer/>
  </div>
</template>

<script>
import navbar from './components/navbar.vue'
import theFooter from './components/theFooter.vue'

export default {
  name: 'App',
  components: {
    navbar,
    theFooter
  }

}
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
</style>
