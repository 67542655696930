<template>
  <div>
    <GmapMap
  :center="{lat:57.02803187368073, lng:9.955618260031882}"
  :zoom="15"
  map-type-id="terrain"
  style="width: 600px; height: 400px"
>
  <GmapMarker
    :position="{lat:57.02803187368073, lng:9.955618260031882}"
    :clickable="true"
  />
</GmapMap>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap'
}
</script>